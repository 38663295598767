const TOUCH_BAR = {
  BACKGROUND: {
    ACTIVE: '#909090'
  },
  WINDOW: 'index'
};

const COMMANDS = {
  COMMAND: 'command',
  APPLICATION: {
    CHANGE_TOUCH_BAR: 'application:set-touch-bar'
  },
  WINDOW: {
    NAVIGATE: 'window:navigate',
    FULLSCREEN: {
      STATE: 'window:fullscreen:state',
      CHANGED: 'window:fullscreen:changed',
      TOGGLE: 'window:fullscreen:toggle'
    },
    CACHE: {
      STATE: 'window:cache:state',
      SIZE: 'window:cache:size',
      CLEAR: 'window:cache:clear'
    }
  }
};

const PAGES = {
  INDEX: 'https://www.radiojavan.com/',
  WEB_PLAYER_INDEX: 'https://play.radiojavan.com',
  RJ_PREMIUM: 'https://www.radiojavan.com/premium',
  RJ_PREMIUM_MONTHLY: 'https://www.radiojavan.com/account/premium?plan=monthly',
  RJ_PREMIUM_YEARLY: 'https://www.radiojavan.com/account/premium?plan=yearly',
  RJ_CONTACT: 'https://www.radiojavan.com/contact',
  RJ_APPS: 'https://www.play.radiojavan.com/apps',
  RJ_ADS: 'https://www.radiojavan.com/ads',
  RJ_PROFILE_PAGE: 'https://www.radiojavan.com/profile',
  RJ_TERMS: 'https://www.radiojavan.com/terms',
  RJ_PRIVACY: 'https://www.radiojavan.com/privacy',
  RJ_ABOUT: 'https://www.radiojavan.com/about',
  RJ_DESKTOP_FEEDBACK: 'desktop-feedback@radiojavan.com',
  RJ_TELEGRAM: 'https://telegram.me/radiojavan',
  RJ_TWITTER: 'https://twitter.com/radiojavan',
  RJ_FB: 'https://www.facebook.com/radiojavan',
  RJ_YOU_TUBE: 'https://youtube.com/radiojavan',
  RJTV_INSTAGRAM: 'http://instagram.com/rjtv',
  RJ_INSTAGRAM: 'http://instagram.com/radiojavan',
  RJ_TIKTOK: 'http://tiktok.com/@radiojavan',
  RJ_HELP_AND_SUPPORT: 'https://radiojavan.zendesk.com/hc',
  RJ_PREMIUM_HELP_QA: 'https://help.radiojavan.com/hc/en-us/articles/360036946972-RJ-Premium',
  RJ_CREATE_EVENTS: 'https://www.radiojavan.com/events/create',
  RJ_MY_ORDERS: 'https://www.radiojavan.com/tickets/myorders',
  RJ_ITUNES_URL_IOS: 'https://apps.apple.com/us/app/radio-javan/id286225933?l=en',
  RJ_GOOGLE_PLAY:
    'https://play.google.com/store/apps/details?id=com.radiojavan.androidradio&hl=en&gl=US',
  RJ_WIKI: 'https://www.wikidata.org/wiki/Q7280868',
  ADMIN: {
    SONG: id => `https://www.radiojavan.com/admin/mp3_edit/${id}`,
    ALBUM: id => `https://www.radiojavan.com/admin/mp3_album_edit/${id}`,
    VIDEO: id => `https://www.radiojavan.com/admin/video_edit/${id}`,
    PODCAST: id => `https://www.radiojavan.com/admin/podcast_edit/${id}`
  }
};

module.exports = {
  TOUCH_BAR,
  COMMANDS,
  PAGES
};
