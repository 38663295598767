import { DEFAULT_RJ_LIVE_STREAM } from '@constants/urls';
import { EVENT_NAMES } from '@constants/machines/now-playing';

import debounce from 'lodash/debounce';
import get from 'lodash/get';

import { isMediaSessionSupported } from '@functions/browser';

import { initHLS } from '@helpers/hls';
import { FadePlay } from '@helpers/dom/fade-play';
import { MediaSession } from '@helpers/dom/media-session';

export const streamMachine = context => {
  return (sendParent, receiver) => {
    let volume;
    let mediaSession;
    let isManuallyPaused = false;

    const data = {
      type: 'live-stream',
      title: window?.appConfig?.tv?.title || 'RJTV',
      photo: '/icons/square-180x180.png'
    };

    const { streamSource } = context;
    const playControl = new FadePlay({ media: streamSource, disabled: true });

    if (isMediaSessionSupported()) {
      mediaSession = new MediaSession({
        media: streamSource,
        data,
        onPlay() {
          sendParent(EVENT_NAMES.LIVE_STREAMING.ACTIVE.TOGGLE_PLAY_PAUSE);
        },
        onPause() {
          sendParent(EVENT_NAMES.LIVE_STREAMING.ACTIVE.TOGGLE_PLAY_PAUSE);
        },
        onStop() {
          sendParent(EVENT_NAMES.LIVE_STREAMING.ACTIVE.TOGGLE_PLAY_PAUSE);
        }
      });
    }

    const src = (() => {
      return get(window, 'appConfig.tv.hls', DEFAULT_RJ_LIVE_STREAM);
    })();

    const destroyHLS = (async () => {
      const hls = await initHLS(streamSource, {
        isLiveStream: true,
        src,
        onManifestParsed: () => {
          // streamSource.muted = true;
          if (!isManuallyPaused) {
            playControl.to(true, {
              volume,
              onDone() {
                mediaSession?.setMetaData();
                mediaSession?.setPlaybackState(true);
                mediaSession?.updatePositionState();
                mediaSession?.initEventListeners();
              },
              onError() {
                sendParent(EVENT_NAMES.LIVE_STREAMING.ACTIVE.TOGGLE_PLAY_PAUSE);

                mediaSession?.setMetaData();
                mediaSession?.setPlaybackState(false);
                mediaSession?.updatePositionState();
                mediaSession?.initEventListeners();
              }
            });
          }
        }
      });

      return hls;
    })();

    // METHODS
    const onPlayExternally = () => {
      sendParent(EVENT_NAMES.LIVE_STREAMING.ACTIVE.PLAYED_EXTERNALLY);
    };

    const onPauseExternally = () => {
      sendParent(EVENT_NAMES.LIVE_STREAMING.ACTIVE.PAUSED_EXTERNALLY);
    };

    const onPlay = () => {
      playControl.to(true, {
        volume,
        onDone() {
          mediaSession?.setMetaData();
          mediaSession?.setPlaybackState(true);
          mediaSession?.updatePositionState();
          mediaSession?.initEventListeners();
        }
      });
    };

    const onPause = () => {
      isManuallyPaused = true;
      playControl.to(false, {
        volume,
        onDone() {
          mediaSession?.setMetaData();
          mediaSession?.setPlaybackState(false);
          mediaSession?.updatePositionState();
        }
      });
    };

    const onWaiting = debounce(() => {
      sendParent(EVENT_NAMES.LIVE_STREAMING.ACTIVE.BUFFERING);
      mediaSession?.setPlaybackState(false);
    }, 3000);

    const onPlaying = () => {
      if (onWaiting.cancel) {
        onWaiting.cancel();
      }

      sendParent(EVENT_NAMES.LIVE_STREAMING.ACTIVE.PLAYING);
      mediaSession?.setPlaybackState(true);
    };

    const onVolumeChange = value => {
      volume = value;
      streamSource.volume = value;
    };

    const cleanup = () => {
      streamSource.removeEventListener('play', onPlayExternally);
      streamSource.removeEventListener('pause', onPauseExternally);
      streamSource.removeEventListener('playing', onPlaying);
      streamSource.removeEventListener('waiting', onWaiting);

      if (!streamSource?.paused) {
        onPause();
      }

      destroyHLS.then(fn => fn());
    };

    const mount = () => {
      streamSource.addEventListener('play', onPlayExternally);
      streamSource.addEventListener('pause', onPauseExternally);
      streamSource.addEventListener('playing', onPlaying);
      streamSource.addEventListener('waiting', onWaiting);

      mediaSession?.setMetaData();
      mediaSession?.setPlaybackState(false);
      mediaSession?.updatePositionState();
      mediaSession?.initEventListeners();
    };

    // ACTIONS
    mount();

    // RECEIVER
    receiver(event => {
      switch (event.type) {
        case 'play':
          onPlay();
          break;

        case 'pause':
          onPause();
          break;

        case EVENT_NAMES.NOW_PLAYING.UPDATE_VOLUME:
          onVolumeChange(event.value);
          break;

        default:
          break;
      }
    });

    // disposal
    return () => {
      cleanup();
    };
  };
};
